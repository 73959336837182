import ws from '../utils/ws';

/* Actions */

export const deviceAllocationPaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: DEVICE_ALLOCATIONS_PAGINATED_LIST,
    payload: ws.get(`/device_allocations/page/${pageNumber}/rows/${pageSize}`)
  });

export const deviceAllocationCalendarList = () => dispatch =>
  dispatch({
    type: DEVICE_ALLOCATION_CALENDAR_LIST,
    payload: ws.get(`/device_allocations/calendar_list/`)
  });

export const deviceAllocationValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_DEVICE_ALLOCATION_VALUE_UPDATE,
    payload: values
  });

export const deviceAllocationValueReset = () => dispatch =>
  dispatch({
    type: CREATE_DEVICE_ALLOCATION_VALUE_RESET
  });

export const saveDeviceAllocation = deviceAllocation => dispatch =>
  dispatch({
    type: SAVE_DEVICE_ALLOCATION,
    payload: ws.post('/device_allocations/', deviceAllocation)
  });

export const deleteDeviceAllocation = deviceAllocation => dispatch =>
  dispatch({
    type: DELETE_DEVICE_ALLOCATION,
    payload: ws.post(`/device_allocations/${deviceAllocation.id}/delete`)
  });

export const editDeviceAllocation = id => dispatch =>
  dispatch({
    type: EDIT_DEVICE_ALLOCATION,
    payload: ws.get(`/device_allocations/${id}`)
  });

export const findDeviceAllocations = () => dispatch =>
  dispatch({
    type: FIND_DEVICE_ALLOCATIONS,
    payload: ws.get('/device_allocations/')
  });

export const findDeviceAllocation = id => dispatch =>
  dispatch({
    type: FIND_DEVICE_ALLOCATION,
    payload: ws.get(`/device_allocations/${id}`)
  });

export const findDeviceAllocationCells = () => dispatch =>
  dispatch({
    type: FIND_DEVICE_ALLOCATION_CELLS,
    payload: ws.get(`/cells/`)
  });

/* Constants */

export const FIND_DEVICE_ALLOCATION_CELLS = 'FIND_DEVICE_ALLOCATIONS_CELLS';
export const FIND_DEVICE_ALLOCATION_CELLS_PENDING =
  'FIND_DEVICE_ALLOCATIONS_CELLS_PENDING';
export const FIND_DEVICE_ALLOCATION_CELLS_FULFILLED =
  'FIND_DEVICE_ALLOCATIONS_CELLS_FULFILLED';

export const DEVICE_ALLOCATION_CALENDAR_LIST =
  'DEVICE_ALLOCATION_CALENDAR_LIST';
export const DEVICE_ALLOCATION_CALENDAR_LIST_PENDING =
  'DEVICE_ALLOCATION_CALENDAR_LIST_PENDING';
export const DEVICE_ALLOCATION_CALENDAR_LIST_FULFILLED =
  'DEVICE_ALLOCATION_CALENDAR_LIST_FULFILLED';

export const FIND_DEVICE_ALLOCATION_CUSTOMER_EVENTS =
  'FIND_DEVICE_ALLOCATION_CUSTOMER_EVENTS';
export const FIND_DEVICE_ALLOCATION_CUSTOMER_EVENTS_PENDING =
  'FIND_DEVICE_ALLOCATION_CUSTOMER_EVENTS_PENDING';
export const FIND_DEVICE_ALLOCATION_CUSTOMER_EVENTS_FULFILLED =
  'FIND_DEVICE_ALLOCATION_CUSTOMER_EVENTS_FULFILLED';

export const DEVICE_ALLOCATIONS_PAGINATED_LIST =
  'DEVICE_ALLOCATIONS_PAGINATED_LIST';
export const DEVICE_ALLOCATIONS_PAGINATED_LIST_PENDING =
  'DEVICE_ALLOCATIONS_PAGINATED_LIST_PENDING';
export const DEVICE_ALLOCATIONS_PAGINATED_LIST_FULFILLED =
  'DEVICE_ALLOCATIONS_PAGINATED_LIST_FULFILLED';

export const CREATE_DEVICE_ALLOCATION_VALUE_UPDATE =
  'CREATE_DEVICE_ALLOCATIONS_VALUE_UPDATE';
export const CREATE_DEVICE_ALLOCATION_VALUE_RESET =
  'CREATE_DEVICE_ALLOCATION_VALUE_RESET';

export const SAVE_DEVICE_ALLOCATION = 'SAVE_DEVICE_ALLOCATION';
export const SAVE_DEVICE_ALLOCATION_PENDING = 'SAVE_DEVICE_ALLOCATION_PENDING';
export const SAVE_DEVICE_ALLOCATION_FULFILLED =
  'SAVE_DEVICE_ALLOCATION_FULFILLED';

export const DELETE_DEVICE_ALLOCATION = 'DELETE_DEVICE_ALLOCATION';
export const DELETE_DEVICE_ALLOCATION_PENDING =
  'DELETE_DEVICE_ALLOCATION_PENDING';
export const DELETE_DEVICE_ALLOCATION_FULFILLED =
  'DELETE_DEVICE_ALLOCATION_FULFILLED';

export const EDIT_DEVICE_ALLOCATION = 'EDIT_DEVICE_ALLOCATION';
export const EDIT_DEVICE_ALLOCATION_FULFILLED =
  'EDIT_DEVICE_ALLOCATION_FULFILLED';

export const FIND_DEVICE_ALLOCATIONS = 'FIND_DEVICE_ALLOCATIONS';
export const FIND_DEVICE_ALLOCATIONS_PENDING =
  'FIND_DEVICE_ALLOCATIONS_PENDING';
export const FIND_DEVICE_ALLOCATIONS_FULFILLED =
  'FIND_DEVICE_ALLOCATIONS_FULFILLED';

export const FIND_DEVICE_ALLOCATION = 'FIND_DEVICE_ALLOCATION';
export const FIND_DEVICE_ALLOCATION_PENDING = 'FIND_DEVICE_ALLOCATION_PENDING';
export const FIND_DEVICE_ALLOCATION_FULFILLED =
  'FIND_DEVICE_ALLOCATION_FULFILLED';
