import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  deviceAllocationLoading: false,
  deviceAllocation: {
    createdBy: {}
  },
  entityPhotos: [],
  cells: [],
  customerEvents: []
};

const deviceAllocationDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_DEVICE_ALLOCATION_PENDING: {
      return {
        ...initialState,
        deviceAllocationLoading: true
      };
    }

    case actionTypes.FIND_DEVICE_ALLOCATION_FULFILLED: {
      return {
        ...state,
        deviceAllocationLoading: false,
        deviceAllocation: action.payload.data.result
      };
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        deviceAllocationLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    case actionTypes.FIND_DEVICE_ALLOCATION_CELLS_PENDING: {
      return {
        ...state,
        cells: initialState.cells
      };
    }

    case actionTypes.FIND_DEVICE_ALLOCATION_CELLS_FULFILLED: {
      return {
        ...state,
        cells: action.payload.data.result
      };
    }

    case actionTypes.FIND_DEVICE_ALLOCATION_CUSTOMER_EVENTS_PENDING: {
      return {
        ...state,
        customerEvents: initialState.customerEvents
      };
    }

    case actionTypes.FIND_DEVICE_ALLOCATION_CUSTOMER_EVENTS_FULFILLED: {
      return {
        ...state,
        customerEvents: action.payload.data.result
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.DEVICE_ALLOCATION &&
        (entityPhoto.entityId === state.deviceAllocation.id ||
          entityPhoto.entityTempId === state.deviceAllocation.creationId)
      ) {
        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.push(entityPhoto);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.DELETE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.DEVICE_ALLOCATION &&
        (entityPhoto.entityId === state.deviceAllocation.id ||
          entityPhoto.entityTempId === state.deviceAllocation.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.splice(photoIndex, 1);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.SAVE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.DEVICE_ALLOCATION &&
        (entityPhoto.entityId === state.deviceAllocation.id ||
          entityPhoto.entityTempId === state.deviceAllocation.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos[photoIndex] = action.payload.data.result;

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    default: {
      return state;
    }
  }
};

export default deviceAllocationDetailsReducer;
