import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  saved: false,
  savedDeviceAllocation: {},
  deleted: false,
  deletedDeviceAllocation: {},
  deviceAllocation: {
    dispatchVenue: {
      id: '617a9b7687e6a359a37c99a7',
      name: 'St Pete Lab'
    },
    returnVenue: {
      id: '617a9b7687e6a359a37c99a7',
      name: 'St Pete Lab'
    }
  },
  entityPhotos: [],
  customers: [],
  venues: [],
  validationMessages: {},
  processing: false,
  cells: [],
  cellsLoading: false
};

const deviceAllocationCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_DEVICE_ALLOCATION_PENDING: {
      return {
        ...state,
        processing: true
      };
    }

    case actionTypes.SAVE_DEVICE_ALLOCATION_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedDeviceAllocation: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages,
        processing: false
      };
    }

    case actionTypes.DELETE_DEVICE_ALLOCATION_PENDING: {
      return {
        ...state,
        processing: true
      };
    }

    case actionTypes.DELETE_DEVICE_ALLOCATION_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deletedDeviceAllocation: action.payload.data.result,
        processing: false
      };
    }

    case actionTypes.FIND_DEVICE_ALLOCATION_CELLS_PENDING: {
      return {
        ...state,
        cellsLoading: true
      };
    }

    case actionTypes.FIND_DEVICE_ALLOCATION_CELLS_FULFILLED: {
      return {
        ...state,
        cells: action.payload.data.result,
        cellsLoading: false
      };
    }

    case actionTypes.EDIT_DEVICE_ALLOCATION_FULFILLED: {
      return {
        ...state,
        deviceAllocation: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.CREATE_DEVICE_ALLOCATION_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_DEVICE_ALLOCATION_VALUE_UPDATE: {
      return {
        ...state,
        deviceAllocation: {
          ...state.deviceAllocation,
          ...action.payload
        }
      };
    }

    case actionTypes.FIND_CUSTOMERS_PENDING: {
      return {
        ...state,
        customers: initialState.customers
      };
    }

    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        customers: action.payload.data
      };
    }

    case actionTypes.FIND_VENUES_PENDING: {
      return {
        ...state,
        venues: initialState.venues
      };
    }

    case actionTypes.FIND_VENUES_FULFILLED: {
      return {
        ...state,
        venues: action.payload.data.result
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.DEVICE_ALLOCATION &&
        (entityPhoto.entityId === state.deviceAllocation.id ||
          entityPhoto.entityTempId === state.deviceAllocation.creationId)
      ) {
        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.push(entityPhoto);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.DELETE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.DEVICE_ALLOCATION &&
        (entityPhoto.entityId === state.deviceAllocation.id ||
          entityPhoto.entityTempId === state.deviceAllocation.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.splice(photoIndex, 1);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.SAVE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.DEVICE_ALLOCATION &&
        (entityPhoto.entityId === state.deviceAllocation.id ||
          entityPhoto.entityTempId === state.deviceAllocation.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos[photoIndex] = action.payload.data.result;

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        deviceAllocationLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default deviceAllocationCreateReducer;
