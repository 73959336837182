import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment-timezone';
import ReactImageMagnify from 'react-image-magnify';
import {
  NumberFormat,
  S3File,
  EventVideo,
  MagnetometerResult,
  Comments,
  SubjectAreaContents,
  AddSubjectAreaContent,
  ConfirmAction,
  EventAnnotations,
  CreateObjectModel,
  CreateObjectType,
  EventPredictionResult,
  EventValidationStatus,
  ImageModal,
  Alert,
  Label
} from 'components';
import { Search } from 'components/SearchBar/components';
import { Link as RouterLink } from 'react-router-dom';
import {
  S3_FILE_TYPES,
  ENTITY_TYPES,
  NEW_ITEM_TRIGGER,
  RADAR_LOCATIONS
} from 'common/constants';
import CopyIcon from '@material-ui/icons/FileCopy';
import FaceIcon from '@material-ui/icons/Face';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Snackbar,
  Grid,
  Link,
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  Divider,
  colors,
  Typography,
  Tooltip,
  Tab,
  Tabs,
  Box,
  CircularProgress,
  Paper,
  TextField
} from '@material-ui/core';
import { CELL_WALKS_MODIFY } from 'common/Right';
import { useDispatch, useSelector } from 'react-redux';
import {
  eventDetailsSubjectAreaContentValueUpdate,
  saveEventAnnotatedSubjectAreaContent,
  findObjectTypes,
  findObjectModels,
  clearEventDetailsEvent,
  deleteEventAnnotatedSubjectAreaContent,
  setEventDetailsSubjectAreaContentSaved,
  setEventDetailsTab,
  setEventDetailsModelSearch,
  setEventDetailsWalkCollectionSearch,
  setEventDetailsWalkCollections,
  setEventDetailsObjectPredictions,
  setEventDetailsEvent,
  setEventDetailsEventLoading,
  generateWalkRadarDataImages,
  generateWalkOpenAiDetails,
  setEventDetailsWalkDetailsAiPromptValue,
  resetEventDetailsWalkDetailsAiPrompt,
  generateFaceInformation
} from 'actions';
import stringUtils from 'utils/stringUtils';
import JSONPrettyMon from 'react-json-pretty/dist/monikai';
import JSONPretty from 'react-json-pretty';
import { EVENT_DATA_SET_TYPES } from 'common/constants';
import theme from 'theme';
import ws from 'utils/ws';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1400,
    maxHeight: '100%',
    maxWidth: '100%',
    overflowY: 'auto'
  },
  content: {
    padding: 0
  },
  container: {
    marginTop: theme.spacing(3)
  },
  selectedButton: {
    backgroundColor: colors.blueGrey[50],
    boxShadow: 'inset 0 0 4px #B0BEC5'
  },
  stickyHeaderTableContainer: {
    maxHeight: 450,
    borderStyle: 'solid',
    borderColor: colors.grey[300],
    borderWidth: 1,
    borderRadius: 5
  },
  contentContainer: {
    borderStyle: 'solid',
    borderColor: colors.grey[300],
    borderWidth: 1,
    borderRadius: 5
  },
  tab: {
    '& .MuiTab-wrapper': {
      display: 'flex' /* or inline-flex */,
      flexDirection: 'row'
    }
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  alert: {
    margin: theme.spacing(3, 0, 3, 0)
  },
  createdSessionId: {
    marginTop: theme.spacing(6)
  },
  contentSectionHeader: {
    padding: 15,
    paddingBottom: 8,
    paddingTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  tableContainer: {
    padding: 0,
    '& td': {
      borderWidth: 0
    }
  },
  modelName: {
    display: 'block',
    maxWidth: 450,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'help'
  },
  commentsContainer: {
    padding: theme.spacing(3)
  },
  addContentButton: {
    marginTop: theme.spacing(2)
  },
  annotationsDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  cardHeaderButton: {
    marginBottom: -3,
    marginTop: 5,
    marginLeft: theme.spacing(1)
  },
  scoreCell: {
    textAlign: 'center'
  },
  tabContent: {
    margin: theme.spacing(2)
  }
}));

const EventDetails = props => {
  const {
    className,
    onNextWalk,
    onPreviousWalk,
    firstWalk,
    lastWalk,
    walkNavigationDisabled
  } = props;

  const {
    eventId,
    event,
    loading,
    objectTypes,
    objectModels,
    subjectAreaContent,
    tab,
    modelSearch,
    walkCollectionSearch,
    walkCollections,
    objectPredictions,
    radarDataImagesGenerating,
    faceInformationGenerating,
    openAiWalkDetailsGenerating,
    openAiWalkDetailsPrompt,
    paginationDisabled
  } = useSelector(state => state.eventDetails);

  useEffect(() => {
    let mounted = true;

    if (mounted && eventId) {
      dispatch(findObjectTypes());
      dispatch(findObjectModels());
      dispatch(setEventDetailsEventLoading(true));
      ws.get(`/events/${eventId}`).then(response => {
        dispatch(setEventDetailsEvent(response.data.result));
      });
      ws.get(`/events/${eventId}/walk_collections/`).then(response => {
        dispatch(setEventDetailsWalkCollections(response.data.result));
      });
      ws.get(`/events/${eventId}/object_predictions/`).then(response => {
        dispatch(setEventDetailsObjectPredictions(response.data.result));
      });
    }

    return () => {
      mounted = false;
    };
  }, [eventId]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const localTimeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [walkLinkCopied, setWalkLinkCopied] = useState(false);
  const [addSubjectAreaContentOpen, setAddSubjectAreaContentOpen] = useState(
    false
  );
  const [
    confirmDeleteSubjectAreaContentOpen,
    setConfirmDeleteSubjectAreaContentOpen
  ] = useState(false);
  const [
    deleteSubjectAreaContentIndex,
    setDeleteSubjectAreaContentIndex
  ] = useState(false);
  const [openCreateObjectType, setOpenCreateObjectType] = useState(false);
  const [openCreateObjectModel, setOpenCreateObjectModel] = useState(false);
  const [radarDataImageStanchion, setRadarDataImageStanchion] = useState(
    'left_stanchion'
  );
  const [zoomedRadarDataImage, setZoomedRadarDataImage] = useState(null);

  const tabs = [
    { value: 'summary', label: 'Summary' },
    { value: 'walk_collections', label: 'Walk Collections' },
    { value: 'model_scores', label: 'Model Scores' },
    { value: 'subject_contents', label: 'Annotations' },
    { value: 'facial_recognition', label: 'Face Recognition' },
    { value: 'video', label: 'Video' },
    { value: 'files', label: 'Files' },
    { value: 'data_validation', label: 'Data Validation' },
    { value: 'weather', label: 'Weather' },
    { value: 'comments', label: 'Comments' },
    { value: 'payload', label: 'Payload' },
    { value: 'rekognition', label: 'Rekognition™' },
    { value: 'open_ai', label: 'Open AI' },
    { value: 'radar_images', label: 'Radar Images' },
    { value: 'pose_estimation', label: 'Pose Estimation' }
  ];

  const stanchionTabs = [
    { value: 'left_stanchion', label: 'Left Stanchion' },
    { value: 'right_stanchion', label: 'Right Stanchion' }
  ];

  const poseEstimationImageFile =
    event && event.walkFiles
      ? event.walkFiles?.filter(
          wf => wf.fileType === 'POSE_ESTIMATION_IMAGE'
        )[0]
      : undefined;

  const entryImageFile =
    event && event.walkFiles
      ? event.walkFiles?.filter(wf => wf.fileType === 'ENTRY_IMAGE')[0]
      : undefined;

  const entryFaceImageFile =
    event && event.walkFiles
      ? event.walkFiles?.filter(
          wf => wf.fileType === 'ENTRY_IMAGE_FACE_ANNOTATED'
        )[0]
      : undefined;

  const exitImageFile =
    event && event.walkFiles
      ? event.walkFiles?.filter(wf => wf.fileType === 'EXIT_IMAGE')[0]
      : undefined;

  const radarDataImageFiles =
    event && event.walkFiles
      ? event.walkFiles?.filter(wf => wf.fileType === 'SENSOR_DATA_IMAGE')
      : [];

  for (let i = 0; i < radarDataImageFiles.length; i++) {
    const file = radarDataImageFiles[i];
    const index = Number(
      file.fileName.substring(
        file.fileName.lastIndexOf('-') + 1,
        file.fileName.indexOf('.')
      )
    );
    const radarLocation = RADAR_LOCATIONS.filter(rl => rl.index === index)[0];
    console.log(radarLocation);

    file.radarLocation = radarLocation;
  }

  for (let i = 0; i < radarDataImageFiles.length; i++) {
    const file = radarDataImageFiles[i];
  }

  const stanchionRadarDataImageFiles = {
    left_stanchion: radarDataImageFiles.filter(
      f => f.radarLocation?.stanchion === 'left_stanchion'
    ),
    right_stanchion: radarDataImageFiles.filter(
      f => f.radarLocation?.stanchion === 'right_stanchion'
    )
  };

  Object.keys(stanchionRadarDataImageFiles).forEach(stanchion => {
    stanchionRadarDataImageFiles[stanchion].sort(
      (r1, r2) => r1.radarLocation.displayOrder - r2.radarLocation.displayOrder
    );
  });

  const imageFiles =
    event && event.walkFiles
      ? event.walkFiles.filter(
          walkFile =>
            walkFile.fileType === 'ENTRY_IMAGE' ||
            walkFile.fileType === 'EXIT_IMAGE' ||
            walkFile.fileType === 'COMBINED_ENTRY_EXIT_IMAGE'
        )
      : [];

  imageFiles.sort((f1, f2) =>
    f1.fileName > f2.fileName ? 1 : f2.fileName > f1.fileName ? -1 : 0
  );

  const postProcessingStages = event?.insightProcessingStageTimes
    ? Object.keys(event.insightProcessingStageTimes)
    : [];
  postProcessingStages.sort();

  const searchedObjectPredictions = objectPredictions.filter(
    op =>
      !modelSearch ||
      op.modelConfiguration.toUpperCase().indexOf(modelSearch.toUpperCase()) >
        -1
  );

  const uniqueWalkCollections = {};
  const searchedWalkCollections = walkCollections
    .filter(ed =>
      uniqueWalkCollections.hasOwnProperty(ed.id)
        ? false
        : (uniqueWalkCollections[ed.id] = true)
    )
    .filter(
      ed =>
        !walkCollectionSearch ||
        ed.name.toUpperCase().indexOf(walkCollectionSearch.toUpperCase()) > -1
    );

  const handleClose = () => {
    dispatch(clearEventDetailsEvent());
  };

  const handleOpenAddSubjectAreaContent = () => {
    dispatch(setEventDetailsSubjectAreaContentSaved(false));
    setAddSubjectAreaContentOpen(true);
  };

  const handleDeleteSubjectAreaContent = subjectAreaContentIndex => {
    setDeleteSubjectAreaContentIndex(subjectAreaContentIndex);
    setConfirmDeleteSubjectAreaContentOpen(true);
  };

  const handleConfirmDeleteSubjectAreaContent = () => {
    dispatch(
      deleteEventAnnotatedSubjectAreaContent(
        event.id,
        deleteSubjectAreaContentIndex
      )
    );
    handleCloseConfirmDeleteSubjectAreaContent();
  };

  const handleCloseConfirmDeleteSubjectAreaContent = () => {
    setConfirmDeleteSubjectAreaContentOpen(false);
    setDeleteSubjectAreaContentIndex(null);
  };

  const handleCloseAddSubjectAreaContent = () => {
    setAddSubjectAreaContentOpen(false);
  };

  const handleSubjectAreaContentFieldChange = (field, value) => {
    if (value === NEW_ITEM_TRIGGER) {
      if (field === 'objectType') {
        handleToggleCreateObjectType();
      } else if (field === 'objectModel') {
        handleToggleCreateObjectModel();
      }
    } else {
      dispatch(eventDetailsSubjectAreaContentValueUpdate(field, value));
    }
  };

  const handleSaveSubjectAreaContent = () => {
    dispatch(
      saveEventAnnotatedSubjectAreaContent(event.id, subjectAreaContent)
    );
  };

  const handleGenerateRadarDataImages = () => {
    dispatch(generateWalkRadarDataImages(event.id));
  };

  const handleGenerateOpenAiWalkDetails = prompt => {
    dispatch(generateWalkOpenAiDetails(event.id, prompt));
  };

  const handleGenerateFaceInformation = () => {
    dispatch(generateFaceInformation(event.id));
  };

  const handleToggleCreateObjectType = () => {
    setOpenCreateObjectType(!openCreateObjectType);
  };

  const handleToggleCreateObjectModel = () => {
    setOpenCreateObjectModel(!openCreateObjectModel);
  };

  const handleTabsChange = (_, value) => {
    dispatch(setEventDetailsTab(value));
  };

  const handleCopyWalkLink = walkId => {
    navigator.clipboard
      .writeText(ws.frontEndUrl(`/walks/cell_walks/?walk_id=${event.id}`))
      .then(
        function() {
          setWalkLinkCopied(true);
        },
        function(err) {
          console.error('Async: Could not copy text: ', err);
        }
      );
  };

  // if (!event || !eventId) {
  //   return null;
  // }

  return (
    <>
      {event && (
        <>
          <Modal onClose={handleClose} open={event !== null}>
            <Card className={clsx(classes.root, className)}>
              <CardHeader
                title={'Walk Info'}
                subheader={
                  moment(event.walkTime)
                    .tz(event.timeZoneId || localTimeZoneId)
                    .format('MMM Do YYYY, h:mm:ss a') +
                  ' (' +
                  (event.timeZoneId || localTimeZoneId) +
                  ')' +
                  ' / ' +
                  event.cellName
                }
                // action={loading && <CircularProgress />}
                action={
                  loading && (
                    <CircularProgress
                      size={20}
                      style={{ marginTop: 8, marginRight: 6, marginBottom: -6 }}
                    />
                  )
                }
              />
              <Divider />
              <CardContent className={classes.content}>
                <Box display={'flex'}>
                  <Tabs
                    style={{ minWidth: 180, borderRight: '1px solid #e0e0e0' }}
                    orientation="vertical"
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    value={tab}
                    variant="scrollable">
                    {tabs.map(tab => (
                      <Tab
                        className={classes.tab}
                        key={tab.value}
                        label={
                          <>
                            <div className={classes.tabLabel}>{tab.label}</div>
                            {tab.value === 'open_ai' && (
                              <img
                                style={{
                                  marginLeft: theme.spacing(1),
                                  width: 16,
                                  height: 16
                                }}
                                src={ws.frontEndUrl('/images/open_ai_logo.png')}
                              />
                            )}
                            {tab.value === 'rekognition' && (
                              <FaceIcon
                                style={{
                                  width: 16,
                                  marginLeft: 8
                                  // marginBottom: -7
                                }}
                                onClick={() => handleCopyWalkLink(event.id)}
                              />

                              // <img
                              //   style={{
                              //     marginLeft: theme.spacing(1),
                              //     width: 16,
                              //     height: 16
                              //   }}
                              //   src={ws.frontEndUrl('/images/rekognition_logo.png')}
                              // />
                            )}
                          </>
                        }
                        value={tab.value}
                      />
                    ))}
                  </Tabs>
                  <Box width={'100%'} className={classes.tabContent}>
                    {tab == 'data_validation' && (
                      <Box className={classes.contentContainer}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell style={{ width: 250 }}>
                                Walk Direction Valid
                              </TableCell>
                              <TableCell>
                                {event.validCenteringWalkDirection === null
                                  ? ''
                                  : event.validCenteringWalkDirection
                                  ? 'Valid'
                                  : 'Invalid'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Face in Region of Interest</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Known Threat Carrier</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    )}
                    {tab == 'summary' && (
                      <Grid container spacing={3}>
                        <Grid item>
                          <Box className={classes.contentContainer}>
                            <PerfectScrollbar>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell style={{ width: 100 }}>
                                      Time
                                    </TableCell>
                                    <TableCell style={{ width: 300 }}>
                                      {moment(event.walkTime)
                                        .tz(event.timeZoneId || localTimeZoneId)
                                        .format('MMM Do YYYY, h:mm:ss a')}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell style={{ width: 100 }}>
                                      Cell
                                    </TableCell>
                                    <TableCell style={{}}>
                                      {event.cellName}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Walk Id</TableCell>
                                    <TableCell>
                                      {event.id}{' '}
                                      <CopyIcon
                                        style={{
                                          cursor: 'pointer',
                                          color: walkLinkCopied
                                            ? colors.green[600]
                                            : colors.blue[600],
                                          width: 12,
                                          marginLeft: 8,
                                          marginBottom: -7
                                        }}
                                        onClick={() =>
                                          handleCopyWalkLink(event.id)
                                        }
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Known Carrier</TableCell>
                                    <TableCell>
                                      {event.knownCarrier ? (
                                        <Link
                                          style={{
                                            marginLeft: theme.spacing(1)
                                          }}
                                          target="_blank"
                                          color="inherit"
                                          component={RouterLink}
                                          to={`/reference/known_carriers/${event.knownCarrier.id}/summary`}
                                          variant="h6">
                                          {event.knownCarrier.name}
                                        </Link>
                                      ) : (
                                        'Unknown'
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Validation</TableCell>
                                    <TableCell>
                                      <EventValidationStatus event={event} />
                                    </TableCell>
                                  </TableRow>
                                  {event.modelName && (
                                    <TableRow>
                                      <TableCell>Model</TableCell>
                                      <TableCell>
                                        {event.modelName && (
                                          <Tooltip title={event.modelName}>
                                            <span className={classes.modelName}>
                                              {event.modelName}
                                            </span>
                                          </Tooltip>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                  <TableRow>
                                    <TableCell>Threshold</TableCell>
                                    <TableCell>
                                      <NumberFormat
                                        value={event.radarThreshold}
                                        percentage
                                        suffix={'%'}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Threats</TableCell>
                                    <TableCell>
                                      {
                                        event.objectOfInterestLocationDescription
                                      }
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Torso</TableCell>
                                    <TableCell style={{ padding: 0 }}>
                                      <Table size="small">
                                        <TableBody>
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                borderWidth: 0,
                                                paddingRight: 0
                                              }}>
                                              Left
                                            </TableCell>
                                            <TableCell
                                              style={{ borderWidth: 0 }}>
                                              <NumberFormat
                                                value={event.radarScoreLeft}
                                                percentage
                                                suffix={'%'}
                                              />
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                borderWidth: 0,
                                                paddingRight: 0
                                              }}>
                                              Right
                                            </TableCell>
                                            <TableCell
                                              style={{ borderWidth: 0 }}>
                                              <NumberFormat
                                                value={event.radarScoreRight}
                                                percentage
                                                suffix={'%'}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Ankle</TableCell>
                                    <TableCell style={{ padding: 0 }}>
                                      <Table size="small">
                                        <TableBody>
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                borderWidth: 0,
                                                paddingRight: 0
                                              }}>
                                              Left
                                            </TableCell>
                                            <TableCell
                                              style={{ borderWidth: 0 }}>
                                              <MagnetometerResult
                                                score={
                                                  event.magnetometerScoreLeft
                                                }
                                              />
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                borderWidth: 0,
                                                paddingRight: 0
                                              }}>
                                              Right
                                            </TableCell>
                                            <TableCell
                                              style={{ borderWidth: 0 }}>
                                              <MagnetometerResult
                                                score={
                                                  event.magnetometerScoreRight
                                                }
                                              />
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Mag. Status</TableCell>
                                    <TableCell>
                                      {event.magnetometerDeactivated !== null
                                        ? event.magnetometerDeactivated
                                          ? 'Disabled'
                                          : 'Active'
                                        : ''}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Prediction</TableCell>
                                    <TableCell className={classes.labelCell}>
                                      <EventPredictionResult
                                        predictionCorrect={
                                          event.predictionCorrect
                                        }
                                      />
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </PerfectScrollbar>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                              {entryImageFile && (
                                <Box style={{ position: 'relative' }}>
                                  <img
                                    style={{ width: 366, borderRadius: 5 }}
                                    src={ws.url(
                                      `/aws/s3/object?bucket=${entryImageFile.bucket}&objectKey=${entryImageFile.key}`
                                    )}
                                  />
                                  <Label
                                    color={colors.blue[600]}
                                    style={{
                                      position: 'absolute',
                                      top: 10,
                                      right: 10
                                    }}>
                                    <span
                                      style={{ marginRight: theme.spacing(1) }}>
                                      Left
                                    </span>
                                    <NumberFormat
                                      value={event.radarScoreLeft}
                                      percentage
                                      suffix={'%'}
                                    />
                                  </Label>
                                  <Label
                                    color={colors.blue[600]}
                                    style={{
                                      position: 'absolute',
                                      top: 10,
                                      left: 10
                                    }}>
                                    <span
                                      style={{ marginRight: theme.spacing(1) }}>
                                      Right
                                    </span>
                                    <NumberFormat
                                      value={event.radarScoreRight}
                                      percentage
                                      suffix={'%'}
                                    />
                                  </Label>
                                </Box>
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box style={{ position: 'relative' }}>
                                {exitImageFile && (
                                  <img
                                    style={{ width: 366, borderRadius: 5 }}
                                    src={ws.url(
                                      `/aws/s3/object?bucket=${exitImageFile.bucket}&objectKey=${exitImageFile.key}`
                                    )}
                                  />
                                )}
                                <Label
                                  color={colors.blue[600]}
                                  style={{
                                    position: 'absolute',
                                    top: 10,
                                    left: 10
                                  }}>
                                  <span
                                    style={{ marginRight: theme.spacing(1) }}>
                                    Left
                                  </span>
                                  <NumberFormat
                                    value={event.radarScoreLeft}
                                    percentage
                                    suffix={'%'}
                                  />
                                </Label>
                                <Label
                                  color={colors.blue[600]}
                                  style={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10
                                  }}>
                                  <span
                                    style={{ marginRight: theme.spacing(1) }}>
                                    Right
                                  </span>
                                  <NumberFormat
                                    value={event.radarScoreRight}
                                    percentage
                                    suffix={'%'}
                                  />
                                </Label>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {tab == 'video' && (
                      <Box display="flex" justifyContent="center">
                        <EventVideo
                          width={770}
                          height={684}
                          eventId={event.id}
                        />
                      </Box>
                    )}

                    {tab == 'pose_estimation' && (
                      <>
                        {event?.poseEstimationKeyPoints?.length > 0 ? (
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Paper>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Point</TableCell>
                                      <TableCell>X</TableCell>
                                      <TableCell>Y</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {event.poseEstimationKeyPoints.map(
                                      (point, i) => (
                                        <TableRow key={i}>
                                          <TableCell>{i + 1}</TableCell>
                                          <TableCell>{point.x}</TableCell>
                                          <TableCell>{point.y}</TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </Paper>
                            </Grid>
                            {poseEstimationImageFile && (
                              <Grid item xs={6}>
                                {event.cellModel &&
                                event.cellModel.startsWith('V') ? (
                                  <ReactImageMagnify
                                    {...{
                                      enlargedImagePosition: 'over',
                                      smallImage: {
                                        isFluidWidth: true,
                                        src: ws.url(
                                          `/aws/s3/object?bucket=${poseEstimationImageFile.bucket}&objectKey=${poseEstimationImageFile.key}`
                                        ),
                                        height: 500
                                      },
                                      largeImage: {
                                        src: ws.url(
                                          `/aws/s3/object?bucket=${poseEstimationImageFile.bucket}&objectKey=${poseEstimationImageFile.key}`
                                        ),
                                        width: 1400,
                                        height: 2500
                                      }
                                    }}
                                  />
                                ) : (
                                  <img
                                    width={890}
                                    src={ws.url(
                                      `/aws/s3/object?bucket=${poseEstimationImageFile.bucket}&objectKey=${poseEstimationImageFile.key}`
                                    )}
                                  />
                                )}
                              </Grid>
                            )}
                          </Grid>
                        ) : (
                          <Paper>
                            <Box
                              style={{
                                textAlign: 'center',
                                padding: theme.spacing(4)
                              }}>
                              <Typography variant="body1">
                                Pose estimation data has not been generated for
                                this walk
                              </Typography>
                            </Box>
                          </Paper>
                        )}
                      </>
                    )}

                    {!event.customerEvent && tab == 'subject_contents' && (
                      <div className={classes.annotationsContainer}>
                        <SubjectAreaContents
                          subjectAreaContents={event.subjectAreaContents}
                        />
                      </div>
                    )}
                    {tab == 'subject_contents' && (
                      <div className={classes.annotationsContainer}>
                        {event.customerEvent && (
                          <>
                            <SubjectAreaContents
                              subjectAreaContents={
                                event.annotatedSubjectAreaContents
                              }
                              onDelete={handleDeleteSubjectAreaContent}
                            />
                            <Button
                              className={classes.addContentButton}
                              variant="contained"
                              size="small"
                              onClick={handleOpenAddSubjectAreaContent}>
                              Add Content
                            </Button>
                          </>
                        )}
                        <Divider className={classes.annotationsDivider} />
                        <EventAnnotations
                          event={event}
                          hideGender={false}
                          hideValidationStatus={false}
                          hideClothingType={!event.customerEvent}
                          hideSpecialInterest={!event.customerEvent}
                        />
                      </div>
                    )}

                    {tab == 'comments' && (
                      <Comments
                        editableRight={CELL_WALKS_MODIFY}
                        entityType={ENTITY_TYPES.EVENT}
                        entityId={event.id}
                        comments={event.comments ? event.comments : []}
                      />
                    )}

                    {tab == 'walk_collections' && (
                      <>
                        <Search
                          style={{ marginBottom: theme.spacing(2) }}
                          autoFocus={true}
                          placeholder="Collection Name"
                          value={walkCollectionSearch}
                          onSearchValueChange={value =>
                            dispatch(setEventDetailsWalkCollectionSearch(value))
                          }
                          className={classes.search}
                          hideSearchButton={true}
                          onSearch={() => {}}
                        />

                        <TableContainer
                          className={classes.stickyHeaderTableContainer}>
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Multi Model</TableCell>
                                <TableCell>Created By</TableCell>
                                <TableCell>Created Date</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {searchedWalkCollections.map(dataSet => (
                                <TableRow key={dataSet.id}>
                                  <TableCell>
                                    <Link
                                      target="_blank"
                                      color="inherit"
                                      component={RouterLink}
                                      to={'/walks/data_sets/' + dataSet.id}
                                      variant="h6">
                                      {dataSet.name}
                                    </Link>
                                  </TableCell>
                                  <TableCell>
                                    {EVENT_DATA_SET_TYPES[dataSet.type]}
                                  </TableCell>
                                  <TableCell>
                                    {dataSet.multiModelDesignated
                                      ? 'Yes'
                                      : 'No'}
                                  </TableCell>
                                  <TableCell>
                                    {dataSet.createdBy.displayName}
                                  </TableCell>
                                  <TableCell>
                                    {dataSet.createdTime &&
                                      moment(dataSet.createdTime).format(
                                        'MMM Do YYYY, h:mm:ss a'
                                      )}
                                  </TableCell>
                                </TableRow>
                              ))}
                              {searchedWalkCollections.length === 0 && (
                                <TableRow>
                                  <TableCell colSpan={5}>
                                    No data available
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}

                    {tab == 'model_scores' && (
                      <>
                        <Search
                          style={{ marginBottom: theme.spacing(2) }}
                          autoFocus={true}
                          placeholder="Model"
                          value={modelSearch}
                          onSearchValueChange={value =>
                            dispatch(setEventDetailsModelSearch(value))
                          }
                          className={classes.search}
                          hideSearchButton={true}
                        />

                        <TableContainer
                          className={classes.stickyHeaderTableContainer}>
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell>Model</TableCell>
                                <TableCell>Torso Left</TableCell>
                                <TableCell>Torso Right</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {searchedObjectPredictions.map(
                                objectPrediction => (
                                  <TableRow
                                    key={objectPrediction.modelConfiguration}>
                                    <TableCell>
                                      {objectPrediction.modelConfiguration}
                                    </TableCell>
                                    <TableCell>
                                      <NumberFormat
                                        value={
                                          objectPrediction.subModelScores[0]
                                        }
                                        precision={2}
                                        fixedDecimalScale={2}
                                        percentage={true}
                                        suffix={'%'}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <NumberFormat
                                        value={
                                          objectPrediction.subModelScores[1]
                                        }
                                        precision={2}
                                        fixedDecimalScale={2}
                                        percentage={true}
                                        suffix={'%'}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              {searchedObjectPredictions.length === 0 && (
                                <TableRow>
                                  <TableCell colSpan={3}>
                                    No data available
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}

                    {tab == 'facial_recognition' && (
                      <Box className={classes.contentContainer}>
                        <PerfectScrollbar>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>Person Name</TableCell>
                                <TableCell>
                                  {event?.facialRecognitionPersonName}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Category</TableCell>
                                <TableCell>
                                  {event?.facialRecognitionCategoryName}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Color</TableCell>
                                <TableCell>
                                  {event?.facialRecognitionCategoryColor}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>User Id</TableCell>
                                <TableCell>
                                  {event?.facialRecognitionUserId}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Person of Interest Name</TableCell>
                                <TableCell>
                                  {
                                    event?.facialRecognitionPersonOfInterest
                                      ?.name
                                  }
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Person of Interest Id</TableCell>
                                <TableCell>
                                  {event?.facialRecognitionPersonOfInterest?.id}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </PerfectScrollbar>
                      </Box>
                    )}

                    {tab == 'payload' && (
                      <PerfectScrollbar>
                        <JSONPretty
                          data={event.payloadJson}
                          theme={JSONPrettyMon}></JSONPretty>
                      </PerfectScrollbar>
                    )}

                    {tab == 'post_processing' && (
                      <>
                        <Box
                          style={{ marginBottom: theme.spacing(2) }}
                          className={classes.contentContainer}>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>Total Processing Time</TableCell>
                                <TableCell>
                                  {event.insightProcessedTime &&
                                    event.insightProcessingStartTime && (
                                      <NumberFormat
                                        value={
                                          event.insightProcessedTime -
                                          event.insightProcessingStartTime
                                        }
                                        suffix={'ms'}
                                      />
                                    )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  Walk Exit Total Processing Time
                                </TableCell>
                                <TableCell>
                                  {event.insightProcessedTime &&
                                    event.exitTime && (
                                      <NumberFormat
                                        value={
                                          event.insightProcessedTime -
                                          event.exitTime
                                        }
                                        suffix={'ms'}
                                      />
                                    )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Box>

                        <TableContainer
                          className={classes.stickyHeaderTableContainer}>
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell>Stage</TableCell>
                                <TableCell>Time</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {postProcessingStages.map(stage => (
                                <TableRow key={stage} hover>
                                  <TableCell>{stage}</TableCell>
                                  <TableCell>
                                    <NumberFormat
                                      value={
                                        event.insightProcessingStageTimes[stage]
                                      }
                                      suffix={'ms'}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                              {postProcessingStages.length === 0 && (
                                <TableRow>
                                  <TableCell colSpan={2}>
                                    No data available
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}

                    {tab == 'rekognition' && (
                      <>
                        {entryFaceImageFile && event.faceInformation ? (
                          <Grid container spacing={3}>
                            <Grid item>
                              <img
                                style={{ width: 385, borderRadius: 5 }}
                                src={ws.url(
                                  `/aws/s3/object?bucket=${entryFaceImageFile.bucket}&objectKey=${entryFaceImageFile.key}`
                                )}
                              />
                            </Grid>
                            <Grid item>
                              <Box className={classes.contentContainer}>
                                <PerfectScrollbar>
                                  <Table style={{ width: 460 }}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell style={{ width: 170 }}>
                                          Attribute
                                        </TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Confidence</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell>Age Range</TableCell>
                                        <TableCell>
                                          {event.faceInformation.ageRangeMin} -{' '}
                                          {event.faceInformation.ageRangeMax}
                                        </TableCell>
                                        <TableCell>N/A</TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Gender</TableCell>
                                        <TableCell>
                                          {event.faceInformation.gender}
                                        </TableCell>
                                        <TableCell>
                                          <NumberFormat
                                            value={
                                              event.faceInformation
                                                .genderConfidence
                                            }
                                            suffix={'%'}
                                          />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Eyes Open</TableCell>
                                        <TableCell>
                                          {event.faceInformation.eyeOpen
                                            ? 'Yes'
                                            : 'No'}
                                        </TableCell>
                                        <TableCell>
                                          <NumberFormat
                                            value={
                                              event.faceInformation
                                                .eyeOpenConfidence
                                            }
                                            suffix={'%'}
                                          />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Smile</TableCell>
                                        <TableCell>
                                          {event.faceInformation.smile
                                            ? 'Yes'
                                            : 'No'}
                                        </TableCell>
                                        <TableCell>
                                          <NumberFormat
                                            value={
                                              event.faceInformation
                                                .smileConfidence
                                            }
                                            suffix={'%'}
                                          />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Sunglasses</TableCell>
                                        <TableCell>
                                          {event.faceInformation.sunglasses
                                            ? 'Yes'
                                            : 'No'}
                                        </TableCell>
                                        <TableCell>
                                          <NumberFormat
                                            value={
                                              event.faceInformation
                                                .sunglassesConfidence
                                            }
                                            suffix={'%'}
                                          />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Mustache</TableCell>
                                        <TableCell>
                                          {event.faceInformation.mustache
                                            ? 'Yes'
                                            : 'No'}
                                        </TableCell>
                                        <TableCell>
                                          <NumberFormat
                                            value={
                                              event.faceInformation
                                                .mustacheConfidence
                                            }
                                            suffix={'%'}
                                          />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Beard</TableCell>
                                        <TableCell>
                                          {event.faceInformation.beard
                                            ? 'Yes'
                                            : 'No'}
                                        </TableCell>
                                        <TableCell>
                                          <NumberFormat
                                            value={
                                              event.faceInformation
                                                .beardConfidence
                                            }
                                            suffix={'%'}
                                          />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Mouth Open</TableCell>
                                        <TableCell>
                                          {event.faceInformation.mouthOpen
                                            ? 'Yes'
                                            : 'No'}
                                        </TableCell>
                                        <TableCell>
                                          <NumberFormat
                                            value={
                                              event.faceInformation
                                                .mouthOpenConfidence
                                            }
                                            suffix={'%'}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </PerfectScrollbar>
                              </Box>
                              <Typography
                                style={{ marginTop: theme.spacing(2) }}
                                variant="body1">
                                Face Confidence:{' '}
                                <NumberFormat
                                  value={event.faceInformation.confidence}
                                  suffix={'%'}
                                />
                              </Typography>
                              <Typography
                                style={{ marginTop: theme.spacing(1) }}
                                variant="body1">
                                Known Carrier:{' '}
                                {event.knownCarrier ? (
                                  <Link
                                    style={{ marginLeft: theme.spacing(1) }}
                                    target="_blank"
                                    color="inherit"
                                    component={RouterLink}
                                    to={`/reference/known_carriers/${event.knownCarrier.id}/summary`}
                                    variant="h6">
                                    {event.knownCarrier.name}
                                  </Link>
                                ) : (
                                  'Unknown'
                                )}
                              </Typography>
                              <Typography
                                style={{ marginTop: theme.spacing(1) }}
                                variant="body1">
                                Generated:{' '}
                                {moment(
                                  event.faceInformation.generatedTime
                                ).format('MMM Do YYYY, h:mm:ss a')}
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container spacing={3}>
                            {entryImageFile ? (
                              <>
                                <Grid item>
                                  <img
                                    style={{ width: 385, borderRadius: 5 }}
                                    src={ws.url(
                                      `/aws/s3/object?bucket=${entryImageFile.bucket}&objectKey=${entryImageFile.key}`
                                    )}
                                  />
                                </Grid>
                                <Grid item>
                                  <Paper>
                                    <Box
                                      style={{
                                        width: 460,
                                        textAlign: 'center',
                                        padding: theme.spacing(4)
                                      }}>
                                      <Typography variant="body1">
                                        Rekognition™ data has not yet been
                                        generated for this walk
                                      </Typography>
                                      <Typography
                                        style={{ marginTop: theme.spacing(1) }}
                                        variant="body1">
                                        Querying Rekognition™ will take around 3
                                        seconds{' '}
                                      </Typography>
                                      {faceInformationGenerating && (
                                        <Box
                                          style={{
                                            marginTop: theme.spacing(2)
                                          }}>
                                          <CircularProgress />
                                        </Box>
                                      )}
                                      <Button
                                        disabled={faceInformationGenerating}
                                        style={{ marginTop: theme.spacing(3) }}
                                        variant="outlined"
                                        onClick={() =>
                                          handleGenerateFaceInformation()
                                        }>
                                        Query Rekognition™
                                      </Button>
                                    </Box>
                                  </Paper>
                                </Grid>
                              </>
                            ) : (
                              <Paper>
                                <Box
                                  style={{
                                    textAlign: 'center',
                                    padding: theme.spacing(4)
                                  }}>
                                  <Typography variant="body1">
                                    An entry image file has not yet been
                                    uploaded for this walk
                                  </Typography>
                                </Box>
                              </Paper>
                            )}
                          </Grid>
                        )}
                      </>
                    )}

                    {tab == 'open_ai' && (
                      <Grid container spacing={3}>
                        <Grid item>
                          <Card style={{ width: 366 }}>
                            <CardHeader title="Prompt" />
                            <CardContent style={{ padding: theme.spacing(2) }}>
                              <TextField
                                style={{ marginTop: 5 }}
                                disabled={openAiWalkDetailsGenerating}
                                fullWidth
                                multiline
                                rows={30}
                                variant="outlined"
                                label="Prompt"
                                value={openAiWalkDetailsPrompt}
                                onChange={e =>
                                  dispatch(
                                    setEventDetailsWalkDetailsAiPromptValue(
                                      e.target.value
                                    )
                                  )
                                }
                              />
                              <CardActions
                                className={classes.actions}
                                style={{
                                  padding: 0,
                                  paddingTop: theme.spacing(2)
                                }}>
                                <Button
                                  disabled={openAiWalkDetailsGenerating}
                                  size="small"
                                  onClick={() =>
                                    dispatch(
                                      resetEventDetailsWalkDetailsAiPrompt()
                                    )
                                  }
                                  variant="outlined">
                                  Reset Prompt
                                </Button>
                                <Button
                                  disabled={openAiWalkDetailsGenerating}
                                  size="small"
                                  onClick={() =>
                                    handleGenerateOpenAiWalkDetails(
                                      openAiWalkDetailsPrompt
                                    )
                                  }
                                  variant="outlined">
                                  Generate Response
                                </Button>
                              </CardActions>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item>
                          <Card style={{ width: 366 }}>
                            <CardHeader
                              title="Response"
                              subheader={
                                event.openAiWalkDetailsResponseTime
                                  ? 'Response time: ' +
                                    moment(
                                      event.openAiWalkDetailsResponseTime
                                    ).format('MMM Do YYYY, h:mm:ss a')
                                  : 'No response generated'
                              }
                            />
                            <CardContent
                              style={{
                                padding: theme.spacing(2),
                                paddingTop: 0
                              }}>
                              <Box
                                style={{
                                  height: 563,
                                  overflowY: 'scroll',
                                  border: '1px solid #D9D9D9',
                                  padding: theme.spacing(2),
                                  whiteSpace: 'pre-wrap',
                                  fontFamily: 'monospace',
                                  fontSize: 12,
                                  borderRadius: 4
                                }}>
                                {openAiWalkDetailsGenerating ? (
                                  <CircularProgress
                                    style={{ marginLeft: 130, marginTop: 100 }}
                                  />
                                ) : (
                                  event.openAiWalkDetailsContent
                                )}
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                              {entryImageFile && (
                                <img
                                  style={{ width: 368, borderRadius: 5 }}
                                  src={ws.url(
                                    `/aws/s3/object?bucket=${entryImageFile.bucket}&objectKey=${entryImageFile.key}`
                                  )}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {tab == 'radar_images' && (
                      <Paper>
                        {event.radarDataImagesGenerated ? (
                          <Box>
                            <Tabs
                              centered={true}
                              className={classes.tabs}
                              onChange={(_, value) => {
                                setRadarDataImageStanchion(value);
                              }}
                              scrollButtons="auto"
                              value={radarDataImageStanchion}
                              variant="fullWidth">
                              {stanchionTabs.map(tab => (
                                <Tab
                                  className={classes.tab}
                                  key={tab.value}
                                  label={tab.label}
                                  value={tab.value}
                                />
                              ))}
                            </Tabs>
                            <Divider
                              style={{ marginBottom: theme.spacing(2) }}
                            />

                            <Grid container>
                              {stanchionRadarDataImageFiles[
                                radarDataImageStanchion
                              ].map(imageFile => (
                                <Grid key={imageFile.key} item xs={12} md={6}>
                                  <Box style={{ position: 'relative' }}>
                                    <Typography
                                      variant="caption"
                                      style={{
                                        position: 'absolute',
                                        top: 6,
                                        left: 100,
                                        display: 'block',
                                        textAlign: 'center'
                                      }}>
                                      {imageFile.radarLocation.description}
                                    </Typography>
                                    <img
                                      style={{
                                        width: 320,
                                        height: 240,
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        display: 'block',
                                        cursor: 'zoom-in'
                                      }}
                                      onClick={() =>
                                        setZoomedRadarDataImage(imageFile)
                                      }
                                      src={ws.url(
                                        `/aws/s3/object?bucket=${imageFile.bucket}&objectKey=${imageFile.key}`
                                      )}
                                    />
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        ) : (
                          <>
                            {event.walkFiles?.filter(
                              wf => wf.fileType === 'SENSOR_DATA'
                            ).length > 0 ? (
                              <Box
                                style={{
                                  textAlign: 'center',
                                  padding: theme.spacing(4)
                                }}>
                                <Typography variant="body1">
                                  Radar images have not yet been generated for
                                  this walk
                                </Typography>
                                <Typography
                                  style={{ marginTop: theme.spacing(1) }}
                                  variant="body1">
                                  Image generation will take around 6 seconds{' '}
                                </Typography>
                                {radarDataImagesGenerating && (
                                  <Box style={{ marginTop: theme.spacing(2) }}>
                                    <CircularProgress />
                                  </Box>
                                )}
                                <Button
                                  disabled={radarDataImagesGenerating}
                                  style={{ marginTop: theme.spacing(3) }}
                                  variant="outlined"
                                  onClick={() =>
                                    handleGenerateRadarDataImages()
                                  }>
                                  Generate Images
                                </Button>
                              </Box>
                            ) : (
                              <Box
                                style={{
                                  textAlign: 'center',
                                  padding: theme.spacing(4)
                                }}>
                                <Typography variant="body1">
                                  A sensor data file has not yet been uploaded
                                  for this walk
                                </Typography>
                              </Box>
                            )}
                          </>
                        )}
                      </Paper>
                    )}

                    {tab == 'files' && (
                      <Box className={classes.contentContainer}>
                        <PerfectScrollbar options={{ suppressScrollY: true }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>File</TableCell>
                                <TableCell>Size</TableCell>
                                <TableCell>NAS Present</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {event.walkFiles?.map(walkFile => (
                                <TableRow key={walkFile.fileName} hover>
                                  <TableCell>{walkFile.fileName}</TableCell>
                                  <TableCell>
                                    {walkFile.contentLength
                                      ? stringUtils.humanFileSize(
                                          walkFile.contentLength
                                        )
                                      : 0}
                                  </TableCell>
                                  <TableCell>
                                    {walkFile.localStorageCopyConfirmed
                                      ? 'Yes'
                                      : 'No'}
                                  </TableCell>
                                  <TableCell>
                                    {S3_FILE_TYPES[walkFile.fileType]}
                                  </TableCell>
                                  <TableCell>
                                    <S3File s3File={walkFile} />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </PerfectScrollbar>
                      </Box>
                    )}

                    {tab == 'weather' && (
                      <Paper>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell />
                              <TableCell>Imperial</TableCell>
                              <TableCell>Metric</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>Temperature</TableCell>
                              <TableCell>
                                <NumberFormat
                                  value={event.weatherTemperatureFahrenheit}
                                  suffix={' F'}
                                />
                              </TableCell>
                              <TableCell>
                                <NumberFormat
                                  value={event.weatherTemperatureCelsius}
                                  suffix={' C'}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Wind</TableCell>
                              <TableCell>
                                <NumberFormat
                                  value={event.weatherWindSpeedMph}
                                  suffix={' Mph'}
                                />
                              </TableCell>
                              <TableCell>
                                <NumberFormat
                                  value={event.weatherWindSpeedKph}
                                  suffix={' Kph'}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Precipitation</TableCell>
                              <TableCell>
                                <NumberFormat
                                  value={event.weatherPrecipitationIn}
                                  suffix={' Inches'}
                                />
                              </TableCell>
                              <TableCell>
                                <NumberFormat
                                  value={event.weatherPrecipitationMm}
                                  suffix={' mm'}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                    )}
                  </Box>
                </Box>
              </CardContent>
              <Divider />
              <CardActions className={classes.actions}>
                {!paginationDisabled && (
                  <>
                    {onPreviousWalk && (
                      <Button
                        disabled={
                          firstWalk || loading || walkNavigationDisabled
                        }
                        onClick={onPreviousWalk}
                        variant="contained">
                        Previous
                      </Button>
                    )}
                    {onNextWalk && (
                      <Button
                        disabled={lastWalk || loading || walkNavigationDisabled}
                        onClick={onNextWalk}
                        variant="contained">
                        Next
                      </Button>
                    )}
                  </>
                )}
                <Button
                  onClick={handleClose}
                  style={{ marginLeft: theme.spacing(4) }}
                  variant="contained">
                  Done
                </Button>
              </CardActions>
            </Card>
          </Modal>

          <AddSubjectAreaContent
            open={addSubjectAreaContentOpen}
            onClose={handleCloseAddSubjectAreaContent}
            subjectAreaContent={subjectAreaContent}
            objectTypes={objectTypes}
            objectModels={objectModels}
            onFieldChange={handleSubjectAreaContentFieldChange}
            onSave={handleSaveSubjectAreaContent}
          />

          <ConfirmAction
            message={
              'Please confirm that you wish to remove this item from the walk.'
            }
            onClose={handleCloseConfirmDeleteSubjectAreaContent}
            onConfirm={handleConfirmDeleteSubjectAreaContent}
            open={confirmDeleteSubjectAreaContentOpen}
          />

          <CreateObjectType
            open={openCreateObjectType}
            onClose={handleToggleCreateObjectType}
          />

          <CreateObjectModel
            open={openCreateObjectModel}
            objectType={subjectAreaContent.objectType}
            onClose={handleToggleCreateObjectModel}
          />

          <ImageModal
            style={{ width: 660 }}
            open={zoomedRadarDataImage !== null}
            onClose={() => setZoomedRadarDataImage(null)}
            imageUri={`/aws/s3/object?bucket=${zoomedRadarDataImage?.bucket}&objectKey=${zoomedRadarDataImage?.key}`}
          />

          <Snackbar
            open={walkLinkCopied}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={2000}
            onClose={() => dispatch(setWalkLinkCopied(false))}>
            <Alert variant="success" message={`Walk link copied`} />
          </Snackbar>
        </>
      )}
    </>
  );
};

EventDetails.displayName = 'EventDetails';

EventDetails.propTypes = {
  event: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

EventDetails.defaultProps = {};

export default EventDetails;
