let config;

const environments = {
  local: {
    name: 'Local development',
    code: 'dev',
    ws: {
      apiHost: 'https://insight-service-dev.xonar.com',
      webSocketHost: 'https://insight-service-dev.xonar.com',
      context: 'insight',
      pythonApiHost: 'https://insightdev-python.xonar.com'
    },
    authRedirectUrl: 'https://insightdev.xonar.com/auth/login'
  },

  uat: {
    name: 'UAT',
    code: 'uat',
    ws: {
      apiHost: 'https://insight-service-uat.xonar.com',
      webSocketHost: 'https://insight-service-uat.xonar.com',
      context: 'insight',
      pythonApiHost: 'https://insightuat-python.xonar.com'
    },
    authRedirectUrl: 'https://insightuat.xonar.com/auth/login'
  },

  production: {
    name: 'Production',
    code: 'prod',
    ws: {
      apiHost: 'https://insight-service.xonar.com',
      webSocketHost: 'https://insight-service.xonar.com',
      context: 'insight',
      pythonApiHost: 'https://insight-python.xonar.com'
    },
    authRedirectUrl: 'https://insight.xonar.com/auth/login'
  }
};

export default class Configuration {
  static getConfig() {
    console.log('process.env.REACT_APP_STAGE', process.env.REACT_APP_STAGE);
    if (config === undefined) {
      console.log('config is undefined');
      const envCode = this.retrieveMetaEnvCode();
      console.log('envCode', envCode);
      if (envCode === '_ENVIRONMENT_') {
        /* The environment has not been configured in meta tag, use local as a default */
        config = environments.local;
      } else {
        config = environments[envCode];
      }
    }
    console.log('config is', config);
    return {
      ...config,
      authClientId:
        '22289539476-s5tuv7qa7i2dpfkb5mnnrahumd58ec7r.apps.googleusercontent.com'
    };
  }

  static retrieveMetaEnvCode() {
    const metas = document.getElementsByTagName('meta');

    let envCode;
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') === 'env') {
        envCode = metas[i].getAttribute('content');
        break;
      }
    }

    return envCode;
  }
}
